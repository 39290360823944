import { Button, CircularProgress } from "@mui/material";
import { useContext } from "react";
import {
  AuthContext as ApacAuthContext,
  IAuthContext,
} from "react-oauth2-code-pkce";
import NavigationBar from "../NavigationBar/NavigationBar";
import NavBarMenu from "./NavBarMenu";
import { AuthContext } from "../../hooks/AuthContext";
import Banner from "../Banner/Banner";

function ApacNavigator() {
  const { token, loginInProgress, logIn } =
    useContext<IAuthContext>(ApacAuthContext);
  const { currentUser, isInitializingCurrentUser } = useContext(AuthContext);

  if (loginInProgress || isInitializingCurrentUser) {
    return <CircularProgress />;
  }

  if (token && currentUser) {
    return (
      <>
        <Banner />
        <NavigationBar />
        <NavBarMenu />
      </>
    );
  } else {
    return (
      <h4>
        Please <Button onClick={() => logIn()}>Sign In</Button>
      </h4>
    );
  }
}
export default ApacNavigator;
