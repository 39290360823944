import { Route, Routes } from "react-router-dom";
import UserManagement from "../UserManagement/UserManagement";
import StoreManagement from "../StoreManagement/StoreManagement";

const NavBarMenu = () => {
  return (
    <Routes>
      <Route index={true} element={<UserManagement />} />
      <Route path="/UserManagement" element={<UserManagement />} />
      <Route path="/StoreManagement" element={<StoreManagement />} />
    </Routes>
  );
};
export default NavBarMenu;
