import {
    Box,
    Button, Checkbox, FormControlLabel,
    IconButton,
    Modal,
    Stack,
    Typography
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import React, {useContext, useState} from 'react'
import TextField from '@mui/material/TextField'
import {SnackbarContext} from "../../../../hooks/SnackbarContext";
import {addUser} from "../../../../services/UserManagementService";
import CreateUserStyles from "./CreateUserStyles";
import UserTypeSelect, {UserType} from "../UserTypeSelect/UserTypeSelect";
import DefaultStoreSelect from "../DefaultStoreSelect/DefaultStoreSelect";
import StoreSelect from "../StoreSelect/StoreSelect";
import {useUsers} from "../../../../queries/Users/useUsers";
import {LoadingButton} from "@mui/lab";
import MuiPhoneNumber from 'material-ui-phone-number'
import EditUserStyles from "../EditUser/EditUserStyles";
import { isApac } from '../../../../helpers/RegionHelper';

const CreateUser = () => {
    const {data: storeUsers, refetch: refetchUsers } = useUsers()
    const {addSnack} = useContext(SnackbarContext)

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [givenName, setGivenName] = useState<string>('')
    const [familyName, setFamilyName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string | null>('')
    const [givenNameErrorMessage, setGivenNameErrorMessage] = useState<string>('')
    const [familyNameErrorMessage, setFamilyNameErrorMessage] = useState<string>('')
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string>('')
    const [defaultStoreErrorMessage, setDefaultStoreErrorMessage] = useState<string>('')
    const [userTypeErrorMessage, setUserTypeErrorMessage] = useState<string>('')
    const [storeList, setStoreList] = useState<string[]>([])
    const [defaultStore, setDefaultStore] = useState<string>('')
    const [userType, setUserType] = useState<UserType>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [username, setUsername] = useState<string>('')
    const [usernameEnabled, setUsernameEnabled] = useState<boolean>(false)
    const [usernameErrorMessage, setUsernameErrorMessage] = useState<string>('')

    const handleClose = (): void => {
        if(!loading) {
            setGivenName('')
            setUsername('')
            setFamilyName('')
            setEmail('')
            setPhoneNumber('')
            setOpenModal(false)
            setDefaultStore('')
            setStoreList([])
            setUserType('')
            clearErrorMessages()
        }
    }

    const handleOpen = (): void => {
        setOpenModal(true)
    }

    const clearErrorMessages = (): void => {
        setGivenNameErrorMessage('')
        setFamilyNameErrorMessage('')
        setEmailErrorMessage('')
        setPhoneNumberErrorMessage('')
        setDefaultStoreErrorMessage('')
        setUserTypeErrorMessage('')
        setUsernameErrorMessage('')
        setUsernameEnabled(false)
    }

    const isUserNameExist = (value:any): boolean => {
        const username = value
        return storeUsers?.some((user) => user.username === username) || false
    }

    const handleUsername = (e: { target: { value: React.SetStateAction<string> } }) => {
        const {value} = e.target
        if(isApac){
          if(value.length > 6) {
            return false
          } else{
            setUsername(value);
            (value.length === 6 && isUserNameExist(value)) ? setUsernameErrorMessage('Username is already taken') : setUsernameErrorMessage('')
          }
       } else{
          setUsername(value)
       }
    }

    const validateUsername = (): boolean => {
        if(isApac){
          if(!username){
            setUsernameErrorMessage('Username is required.')
            return false
          } else if(username.length < 6){
            setUsernameErrorMessage('Username must be 6 characters.')
            return false
          }
        } 
        return true
    }

    function handlePhoneChange(value: string): void {
        let validPhone = value.replace(/[{()}-]/g, '')
        validPhone = validPhone.replaceAll(' ', '')
        setPhoneNumber(validPhone)
    }

    function validateEmail(): boolean {
        const emailRegex =
            /* eslint-disable */
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if(isApac){
                return email ? emailRegex.test(email.toLowerCase()) : true
            }
            return emailRegex.test(email.toLowerCase())
    }

    function validatePhoneNumber(): boolean {
        if(isApac){
            return (phoneNumber && phoneNumber.length > 3) ? phoneNumber.length === 12 : true
        }
        return !phoneNumber || phoneNumber.length <= 2 || phoneNumber.length === 12
    }

    function validateAddDriverFields() {
        let validate = true
        if(!validateUsername()){
            validate = false
        }
        if (!givenName) {
            setGivenNameErrorMessage('First name is required.')
            validate = false
        }
        if (!familyName) {
            setFamilyNameErrorMessage('Last name is required.')
            validate = false
        }
        if (!validateEmail()) {
            setEmailErrorMessage('Please enter a valid email address.')
            validate = false
        }
        if(!defaultStore) {
            setDefaultStoreErrorMessage('Please select a default store.')
            validate = false
        }
        if(!userType) {
            setUserTypeErrorMessage('Please select a user type')
            validate = false
        }
        if(!validatePhoneNumber()) {
            setPhoneNumberErrorMessage('Please enter a valid phone number')
            validate = false
        }
        return validate
    }

    async function addNewDriver(): Promise<void> {
        clearErrorMessages()
        if (validateAddDriverFields()) {
            setLoading(true)
            try {
                await addUser({
                    email: email,
                    familyName: familyName,
                    givenName: givenName,
                    defaultStoreNumber: defaultStore,
                    storeList: storeList,
                    userType: userType,
                    username: isApac ? username : usernameEnabled ? username : null,
                    ... phoneNumber?.length === 12 && { phoneNumber },
                })
                addSnack({
                    severity: 'success',
                    message: 'Success!',
                    action: null,
                    duration: 3000
                })
                await refetchUsers()
                handleClose()
            } catch (e: any) {
                addSnack({
                    severity: 'error',
                    message: `Error creating new driver: ${e}`,
                    action: null,
                })
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <Box>
            <Button sx={{marginX: '5px'}} variant={'primary'} onClick={handleOpen}>
                Add User
            </Button>
            <Modal
                sx={CreateUserStyles.addDriverModal}
                open={openModal}
                onClose={handleClose}
                aria-labelledby="add-driver-modal"
            >
                <Stack sx={CreateUserStyles.addDriverModalContainer}>
                    <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                        <Typography sx={CreateUserStyles.modalTitle}>Create New Driver</Typography>
                        <IconButton size={'small'} onClick={handleClose}>
                            <ClearIcon fontSize="medium"/>
                        </IconButton>
                    </Stack>
                    <Stack direction={"row"} width={'100%'}>
                        {!isApac && <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usernameEnabled}
                                    onChange={() => {
                                        setUsernameEnabled(!usernameEnabled)
                                    }}
                                    inputProps={{ "aria-label": "primary checkbox"}}
                                />
                            }
                            label="enable username"
                            labelPlacement="end"
                        />}
                        
                        <TextField
                            fullWidth
                            disabled={!usernameEnabled && !isApac}
                            sx={EditUserStyles.editDriverTextField}
                            inputProps={{ 'data-testid': 'username', type: isApac? 'number' : 'text' }}
                            label="User Name*"
                            error={!!usernameErrorMessage}
                            helperText={usernameErrorMessage}
                            value={username}
                            onChange={handleUsername}
                        />
                    </Stack>
                    <Typography sx={CreateUserStyles.addDriverLabel}>Name</Typography>
                    <TextField
                        sx={CreateUserStyles.addDriverTextField}
                        inputProps={{'data-testid': 'firstname'}}
                        type="text"
                        label="First Name*"
                        error={!!givenNameErrorMessage}
                        helperText={givenNameErrorMessage}
                        value={givenName}
                        onChange={(firstName) => setGivenName(firstName.target.value)}
                    />
                    <TextField
                        sx={CreateUserStyles.addDriverTextField}
                        inputProps={{'data-testid': 'lastname'}}
                        type="text"
                        label="Last Name*"
                        error={!!familyNameErrorMessage}
                        helperText={familyNameErrorMessage}
                        value={familyName}
                        onChange={(lastName) => setFamilyName(lastName.target.value)}
                    />
                    <Typography sx={CreateUserStyles.addDriverLabel}>Email</Typography>
                    <TextField
                        sx={CreateUserStyles.addDriverTextField}
                        inputProps={{'data-testid': 'emailaddress'}}
                        type="text"
                        label={`Email${isApac ? '' : '*'}`}
                        error={!!emailErrorMessage}
                        helperText={emailErrorMessage}
                        value={email}
                        onChange={(email) => setEmail(email.target.value)}
                    />
                    <Typography sx={CreateUserStyles.addDriverLabel}>Phone Number</Typography>
                    <MuiPhoneNumber
                        defaultCountry={isApac ? 'au' : 'us'}
                        onChange={(e) => handlePhoneChange(e as string)}
                        inputProps={{ 'data-testid': 'phonenumber' }}
                        onlyCountries={isApac ? ['au'] : ['us', 'ca']}
                        error={!!phoneNumberErrorMessage}
                        helperText={phoneNumberErrorMessage}
                        type={'text'}
                        countryCodeEditable={false}
                        disableAreaCodes={true}
                        InputProps={{ disableUnderline: true }}
                        value={phoneNumber}
                        sx={{
                            ...CreateUserStyles.phoneNumberField,
                            borderColor: phoneNumberErrorMessage ? '#D32F2F' : '#bfbfbf'
                        }}
                    />
                    <Typography sx={CreateUserStyles.addDriverLabel}>User Type</Typography>
                    <UserTypeSelect userType={userType} setUserType={setUserType} userTypeErrorMessage={userTypeErrorMessage}/>
                    <Typography sx={CreateUserStyles.addDriverLabel}>Store(s)</Typography>
                    <StoreSelect storeList={storeList} setStoreList={setStoreList}/>
                    <Typography sx={CreateUserStyles.addDriverLabel}>Default Store (Required)</Typography>
                    <DefaultStoreSelect defaultStore={defaultStore} setDefaultStore={setDefaultStore} defaultStoreErrorMessage={defaultStoreErrorMessage} storeList={storeList}/>
                    <Stack direction={"row"} justifyContent='space-between' width={'100%'} marginTop={1}>
                        <Button variant={'secondary'} onClick={(handleClose)} sx={{width: '45%'}}>
                            Cancel
                        </Button>
                        <LoadingButton
                            variant={'primary'}
                            onClick={addNewDriver}
                            sx={{width: '45%'}}
                            loading={loading}
                            disabled={defaultStore==''}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Modal>
        </Box>
    )
}

export default CreateUser
