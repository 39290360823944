import {Amplify} from 'aws-amplify'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter} from 'react-router-dom'
import {ThemeProvider} from '@mui/material'
import './App.css';
import {AuthProvider} from "./hooks/AuthContext";
import {SnackbarProvider} from "./hooks/SnackbarContext";
import theme from "./hooks/theme";
import Navigator from "./components/Navigator/Navigator";
import {
  AuthProvider as ApacAuthProvider,
  TAuthConfig,
  TRefreshTokenExpiredEvent,
} from "react-oauth2-code-pkce";
import { isApac } from "./helpers/RegionHelper";
import ApacNavigator from "./components/Navigator/ApacNavigator";

// configuration for PING
const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_PING_CLIENT_ID ?? "",
  authorizationEndpoint: `${process.env.REACT_APP_PING_URL}/as/authorization.oauth2`,
  tokenEndpoint: `${process.env.REACT_APP_PING_URL}/as/token.oauth2`,
  redirectUri: process.env.REACT_APP_PING_REDIRECT_URL ?? "",
  logoutEndpoint: `${process.env.REACT_APP_PING_URL}/idp/startSLO.ping`,
  logoutRedirect: process.env.REACT_APP_PING_REDIRECT_URL ?? "",
  scope: "openid profile groups phone",
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    event.logIn(undefined, undefined, "popup"),
};

const queryClient = new QueryClient()

function App() {
    !isApac && Amplify.configure({
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
        identityPoolRegion: process.env.REACT_APP_COGNITO_USER_POOL_ID
    })

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <SnackbarProvider>
              {isApac ? (
                <ApacAuthProvider authConfig={authConfig}>
                  <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                      <ApacNavigator />
                    </QueryClientProvider>
                  </AuthProvider>
                </ApacAuthProvider>
              ) : (
                <AuthProvider>
                  <QueryClientProvider client={queryClient}>
                    <Navigator />
                  </QueryClientProvider>
                </AuthProvider>
              )}
            </SnackbarProvider>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    );
}

export default App;
