import {createContext, ReactElement, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {AuthHelper, getApacUserType} from "../services/AuthHelper";
import { IAuthContext, AuthContext as ApacAuthContext } from "react-oauth2-code-pkce";
import { SnackbarContext } from './SnackbarContext'
import { isApac } from '../helpers/RegionHelper';
import { getApacUserByUserName, UserDataTypes } from '../services/ApacUserService';
import { editDriver } from '../services/UserManagementService';

export const AuthContext = createContext<AuthContextType>({
    currentUser: null,
    isInitializingCurrentUser: true,
    signIn: () => {
    },
    signOut: () => {
    },
})

type AuthContextType = {
    currentUser: CurrentUser | null
    isInitializingCurrentUser: boolean
    signIn: (username: string, password: string) => void
    signOut: () => void
}

export interface CurrentUser {
    id: string,
    email: string,
    groups?:string[]
}

type AuthProviderProps = {
    children: ReactElement
}

export function AuthProvider({children}: AuthProviderProps): ReactElement {
    let navigate = useNavigate()

    const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)
    const [isInitializingCurrentUser, setIsInitializingCurrentUser] = useState<boolean>(true)
    const { token, loginInProgress, logIn, logOut } = useContext<IAuthContext>(ApacAuthContext);
    const { addSnack } = useContext(SnackbarContext)

    // Compare user details with ping and update the database accordingly
    const fetchAndCompareUserData = async () => {
        try {
        const pingUserData = await AuthHelper.currentSignedInApacUser(token)
            try {
                const userFromDB: UserDataTypes = await getApacUserByUserName(pingUserData.username)
                const currentUser = {
                    id: pingUserData.username,
                    email: pingUserData.UserPrincipalName,
                    groups: pingUserData.groups
                };
                
                setCurrentUser(currentUser)
                // It will call the user update endpoint if data mismatched with ping
                const { payload, isUpdate } = AuthHelper.compareApacUser(pingUserData, userFromDB)
                if (isUpdate) {
                    try {
                        await editDriver(pingUserData.username, payload)
                    } catch (e) {
                        console.error('Unable to update user', e)
                    }
                }
            } catch (error: any) {
                addSnack({
                severity: 'error',
                message: error.response.status === 404 ? 'Please contact store admin to get added to the store.' : error.message,
                duration: 3000
                })
            }
        } catch (e: any) {
            addSnack({
                severity: 'error',
                message: e.message,
                duration: 3000
            })
        } finally {
            setIsInitializingCurrentUser(false)
        }
    }

    useEffect(() => {
      if (isApac) {
        if (!loginInProgress && !token) logIn(); 
        if (!loginInProgress && token) fetchAndCompareUserData()
      } else {
        AuthHelper.currentSignedInUser()
          .then((user) => {
            setCurrentUser(user);
          })
          .catch((e) => console.error(e.message))
          .finally(() => setIsInitializingCurrentUser(false));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logIn, loginInProgress, token]);

    async function signIn(username: string, password: string): Promise<void> {
        try {
            const currentUser: CurrentUser | null = await AuthHelper.signIn({username, password})
            setCurrentUser(currentUser)
            navigate('/')
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async function signOut(): Promise<void> {
        if(isApac) {
            setCurrentUser(null)
            logOut()
        } else {
        try {
            await AuthHelper.signOut()
        } catch (error: any) {
            console.error('Error signing out')
        } finally {
            setCurrentUser(null)
            navigate('/')
        }
    }
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                isInitializingCurrentUser,
                signOut,
                signIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
