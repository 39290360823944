import {Dispatch, SetStateAction, useContext, useState} from 'react'
import {Button, CircularProgress, IconButton, Modal, Stack, Typography} from "@mui/material";
import {User} from "../../UserManagement";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import UserTypeSelect, {UserType} from "../UserTypeSelect/UserTypeSelect";
import DefaultStoreSelect from "../DefaultStoreSelect/DefaultStoreSelect";
import StoreSelect from "../StoreSelect/StoreSelect";
import EditUserStyles from "./EditUserStyles";
import {editDriver, resendPW} from "../../../../services/UserManagementService";
import {SnackbarContext} from "../../../../hooks/SnackbarContext";
import {useUsers} from "../../../../queries/Users/useUsers";
import {LoadingButton} from "@mui/lab";
import MuiPhoneNumber from "material-ui-phone-number";
import { isApac } from '../../../../helpers/RegionHelper';

type EditUserProps = {
    user: User,
    setUser: Dispatch<SetStateAction<User | null>>
}

function EditUser({user, setUser}: EditUserProps) {
    const {refetch: refetchUsers} = useUsers()
    const {addSnack} = useContext(SnackbarContext)
    const [givenName, setGivenName] = useState<string>(user.givenName)
    const [familyName, setFamilyName] = useState<string>(user.familyName)
    const [email, setEmail] = useState<string>(user.email)
    const [phoneNumber, setPhoneNumber] = useState<string | null>(user.phoneNumber ? user.phoneNumber : null)
    const [defaultStore, setDefaultStore] = useState<string>(user.defaultStoreNumber)
    const [storeList, setStoreList] = useState<string[]>(user.storeList)
    const [userType, setUserType] = useState<UserType>(user.userType)
    const [username] = useState<string>(user.username)

    const [givenNameErrorMessage, setGivenNameErrorMessage] = useState<string>('')
    const [familyNameErrorMessage, setFamilyNameErrorMessage] = useState<string>('')
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string>('')
    const [defaultStoreErrorMessage, setDefaultStoreErrorMessage] = useState<string>('')
    const [userTypeErrorMessage, setUserTypeErrorMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    function handleClose(): void {
        if (!loading) {
            setUser(null)
        }
    }

    function handlePhoneChange(value: string): void {
        let validPhone = value.replace(/[{()}-]/g, '')
        validPhone = validPhone.replaceAll(' ', '')
        setPhoneNumber(validPhone)
    }

    function validateEmail(): boolean {
        const emailRegex =
            /* eslint-disable */
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if(isApac){
                return email ? emailRegex.test(email.toLowerCase()) : true
            }
            return emailRegex.test(email.toLowerCase())
    }

    function validatePhoneNumber(): boolean {
        return !phoneNumber || phoneNumber.length <= 2 || phoneNumber.length === 12
    }

    function validateEditUserFields(): boolean {
        let validate = true
        if (!givenName) {
            setGivenNameErrorMessage('First name is required.')
            validate = false
        }
        if (!familyName) {
            setFamilyNameErrorMessage('Last name is required.')
            validate = false
        }
        if (!validateEmail()) {
            setEmailErrorMessage('Please enter a valid email address.')
            validate = false
        }
        if (!defaultStore) {
            setDefaultStoreErrorMessage('Please select a default store.')
            validate = false
        }
        if (!userType) {
            setUserTypeErrorMessage('Please select a user type')
            validate = false
        }
        if(isApac){
            if(phoneNumber && phoneNumber?.length > 3 && !validatePhoneNumber()) {
                setPhoneNumberErrorMessage('Please enter valid phone number')
                validate = false
            }
        } else{
            if(!validatePhoneNumber()) {
                setPhoneNumberErrorMessage('Please enter valid phone number')
                validate = false
            }
        }
        
        return validate
    }

    async function handleEditDriver(): Promise<void> {
        if (validateEditUserFields()) {
            setLoading(true)
            try {
                await editDriver(user.username, {
                    email,
                    username,
                    givenName,
                    familyName,
                    defaultStoreNumber: defaultStore,
                    userType,
                    storeList: storeList,
                    ... (isApac && phoneNumber && (phoneNumber?.length===3 ? { phoneNumber: '' } : { phoneNumber })) || (phoneNumber?.length === 12 && { phoneNumber }),
                })
                addSnack({
                    severity: 'success',
                    message: 'Success!',
                    action: null,
                    duration: 3000
                })
                await refetchUsers()
                handleClose()
            } catch (e) {
                addSnack({
                    severity: 'error',
                    message: `Error editing user: ${e}`,
                    action: null,
                })
            } finally {
                setLoading(false)
            }
        }
    }

    async function handleResendTmpPw() {
        try {
            await resendPW(user.username)
            addSnack({
                severity: 'success',
                message: 'Success!',
                action: null,
                duration: 3000
            })
        } catch (e: any) {
            addSnack({
                severity: 'error',
                message: `Error editing user: ${e}`,
                action: null,
            })
        }
    }

    if (!storeList) { return <CircularProgress/> }

    return (
        <Modal
            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            open={!!user}
            onClose={handleClose}
            aria-labelledby="edit-user-modal">
            <Stack sx={EditUserStyles.editDriverModalContainer}>
                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Typography sx={EditUserStyles.modalTitle}>Edit User</Typography>
                    <IconButton size={'small'} onClick={handleClose}>
                        <ClearIcon fontSize="medium"/>
                    </IconButton>
                </Stack>
                <Typography sx={EditUserStyles.editDriverLabel}>Username</Typography>
                <Typography sx={EditUserStyles.usernameSubheader}>{username}</Typography>
                <Typography sx={EditUserStyles.editDriverLabel}>Name</Typography>
                <TextField
                    sx={EditUserStyles.editDriverTextField}
                    inputProps={{'data-testid': 'firstname'}}
                    type="text"
                    label="First Name*"
                    error={!!givenNameErrorMessage}
                    helperText={givenNameErrorMessage}
                    value={givenName}
                    onChange={(firstName) => setGivenName(firstName.target.value)}
                />
                <TextField
                    sx={EditUserStyles.editDriverTextField}
                    inputProps={{'data-testid': 'lastname'}}
                    type="text"
                    label="Last Name*"
                    error={!!familyNameErrorMessage}
                    helperText={familyNameErrorMessage}
                    value={familyName}
                    onChange={(lastName) => setFamilyName(lastName.target.value)}
                />
                <Typography sx={EditUserStyles.editDriverLabel}>Email</Typography>
                <TextField
                    sx={EditUserStyles.editDriverTextField}
                    inputProps={{'data-testid': 'emailaddress'}}
                    type="text"
                    label={`Email${isApac ? '' : '*'}`}
                    error={!!emailErrorMessage}
                    helperText={emailErrorMessage}
                    value={email}
                    onChange={(email) => setEmail(email.target.value)}
                />
                <Typography sx={EditUserStyles.editDriverTextField}>Phone Number</Typography>
                <MuiPhoneNumber
                    defaultCountry={isApac ? 'au' : 'us'}
                    onChange={(e) => handlePhoneChange(e as string)}
                    inputProps={{ 'data-testid': 'phonenumber' }}
                    onlyCountries={isApac ? ['au'] : ['us', 'ca']}
                    error={!!phoneNumberErrorMessage}
                    helperText={phoneNumberErrorMessage}
                    type={'text'}
                    countryCodeEditable={false}
                    disableAreaCodes={true}
                    InputProps={{ disableUnderline: true }}
                    value={phoneNumber}
                    sx={{
                        ...EditUserStyles.phoneNumberField,
                        borderColor: phoneNumberErrorMessage ? '#D32F2F' : '#bfbfbf'
                    }}
                />
                <Typography sx={EditUserStyles.editDriverLabel}>User Type</Typography>
                <UserTypeSelect userType={userType} setUserType={setUserType}
                                userTypeErrorMessage={userTypeErrorMessage}/>
                <Typography sx={EditUserStyles.editDriverLabel}>Store(s)</Typography>
                <StoreSelect storeList={storeList} setStoreList={setStoreList}/>
                <Typography sx={EditUserStyles.editDriverLabel}>Default Store</Typography>
                <DefaultStoreSelect defaultStore={defaultStore} setDefaultStore={setDefaultStore}
                                    defaultStoreErrorMessage={defaultStoreErrorMessage} storeList={storeList}/>
                <Stack direction={"row"} justifyContent='space-between' width={'100%'} marginTop={1}>
                    <Button variant={'secondary'} onClick={handleClose} sx={{width: '45%'}}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant={'primary'}
                        onClick={handleEditDriver}
                        sx={{width: '45%'}}
                        loading={loading}
                    >
                        Save
                    </LoadingButton>
                </Stack>
                {!isApac && (<Button onClick={handleResendTmpPw}>Resend Password</Button>)}
            </Stack>
        </Modal>
    )
}

export default EditUser
